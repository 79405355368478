//Styles used by MUI components
import logoImage from '../../Images/logo.svg'



export const buttonStyles = {
    my: 2,
    display: 'block',
    '&:hover':{
        md: { 
            background: "#a15120 !important"
        }
        
    },
    fontFamily:'Poppins',
    fontSize: {
        lg:'14px',
        xl:'16px'

    },
    color: {
        xs:'black',
        sm:'black',
        md:'white',
        lg:'white'
    }
   
}


export const menuItemStyles = {
    display: 'block',
    '&:focus':{
        background: "#ffffff",
        opacity: "0.5"
    },
}


export const boxStyles = {
    flexGrow: 1, 
    display: { xs: 'none', md: 'flex' },
    justifyContent:'right',
    //brown color
    background:"#bc6630",
    
}

export const logoStyles = {
    mr: 2,
    display: { xs: 'none', md: 'flex' },
    color: 'inherit',
    textDecoration: 'none',
    fontSize:'small',
    // fontFamily: 'monospace',
}

// "color: rgba(0, 0, 0, 0.87)"

export const navBarStyles = {
    backgroundColor:'#bc6630'
}

export const mobileTitleStyles = {
    fontSize:'3.5vw',
    color:'white',
    fontFamily:'Poppins'
}

export const mobileButtonStyles = {
    my: 2,
    display: 'block',
    '&:hover':{
        md: { 
            background: "#a15120 !important"
        }
    },
    fontFamily:'Poppins',
    fontSize: {
        lg:'14px',
        xl:'16px'

    },
    color: {
        xs:'black',
        sm:'black',
        md:'white',
        lg:'white'
    }
   
}