import CompareContainer from "./CompareContainer/CompareContainer"


const CompareImages = (props) =>{
    return(
    <>
        <CompareContainer description = {props.compareData.description} before = {props.compareData.before} after = {props.compareData.after}/>
    </>)
}

export default CompareImages;