
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ContentCard from '../Content/Content';
import { Grid } from '@mui/material'
import { gridStyles, gridItemStyles, gridArrowStyles } from './styles'
const CompareContainer = (props) =>{
    return (
        <Grid container spacing = {0} sx = {gridStyles}>
            {/* Need to add label with desc here */}
            <Grid  item sx ={gridItemStyles} xs = {5} sm={3} md ={3} lg = {3}>
                <ContentCard data = {props.before}/>
            </Grid>
            <Grid  item sx ={gridArrowStyles} xs = {0} sm={1} md={1} lg = {1}>
                <ArrowForwardIcon/>
            </Grid>
            <Grid  item sx ={gridItemStyles} xs = {5} sm={3} md={3} lg = {3}>
                <ContentCard data = {props.after}/>
            </Grid>
        </Grid>
    )
}

export default CompareContainer;