import MediaCard from '../../MediaCard/MediaCard'
import {contentStyles, titleStyles, paperStyles, gridStyles, gridItemStyles} from './styles'
import Paper from '@mui/material/Paper';
import ServicesInfo from '../ServicesInfo/ServicesInfo'
import ServiceAreas from '../ServiceAreas/ServiceAreas'
import AdditionalInfo from '../AdditionalInfo/AdditionalInfo';
import { Typography, Box, Grid } from '@mui/material';
const ContentEng = () =>{
    return(
        
        <Paper sx={paperStyles} elevation={3}>
            <Box sx = {contentStyles}>            
                <Typography variant = 'h2' sx={titleStyles}>
                    STUCCO STONE CONSTRUCTION
                </Typography>
                <Grid container  spacing = {0} sx = {gridStyles}>
                    <Grid  item sx ={gridItemStyles} xs = {12} md = {4}>
                        <MediaCard/>
                    </Grid>
                    <Grid item sx ={gridItemStyles} xs = {12} md = {4}>
                        <ServicesInfo/>
                        <ServiceAreas/>
                        <AdditionalInfo/>
                    </Grid>
                </Grid>
            </Box>
         </Paper>

    )
}

export default ContentEng;