import React, {useState}  from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {Link} from 'react-router-dom';
import {buttonStyles, boxStyles, logoStyles, navBarStyles,mobileTitleStyles, menuItemStyles, mobileButtonStyles} from './styles';
import Logo from '../../Images/logo.svg'
import Tooltip from '@mui/material/Tooltip';

const pages = ['home', 'services', 'contact'];



const ResponsiveAppBar = () => {

  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };


  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  
  const setRoute = (page) =>{
    let route = (page === "home"? "/":"/"+page)
    return route
  }
  



  return (
    <AppBar position="static" sx = {navBarStyles}>
      <Container maxWidth="xxl">
        <Toolbar disableGutters >
          <IconButton
            href="/"
            sx={logoStyles}
          >
            <img src = {Logo} width="150px"/>
          </IconButton>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {/* Hamburger */}
              {pages.map((page) => (
                <Link key ={page} to = {setRoute(page)} style={{ textDecoration: 'none' }}>
                    <MenuItem 
                    key = {page}
                    sx={mobileButtonStyles}
                    onClick={handleCloseNavMenu}>
                        <Button sx ={buttonStyles}>{page.toUpperCase()}</Button>
                    </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <Tooltip title="Home">
              <Button style = {mobileTitleStyles}>Stucco Stone Construction</Button>
            </Tooltip>
          </Typography>
          
          <Box sx={boxStyles}>
            {/* Nav Bar */}
            {pages.map((page) => (
            <Link to = {setRoute(page)} style={{ textDecoration: 'none' }} key ={page}>
                <Button
                onClick={handleCloseNavMenu}
                sx={buttonStyles}
                >
                {page}
                </Button>
            </Link>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;