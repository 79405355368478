export const cardContentStyles = {
    padding:{
        xs:'5px',
        sm:'10px',
        lg:'15px'
    }
}

export const cardStyles = {
    
    maxWidth: {
        xs:'150px',
        sm:'250px',
        lg:'345px'
    }
}
export const cardMediaStyles = {
    height:{
        xs:'200px',
        sm:'370px',
        lg:'400px'
    }
}

export const contentLabelStyles ={
    fontSize:'1.2rem',
    fontFamily:'Poppins Medium Italic',}