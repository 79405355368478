export const contentStyles = {
    display:'grid',
    justifyItems:'center'
    }

export const titleStyles = {
    display:'flex',
    height: '7.5rem',
    width:'100%',
    fontSize : '2.5rem',
    justifyContent:'center',
    alignItems:'center',
    textAlign:'center',
    backgroundColor:'#bc6630',
    color:'white',
    fontFamily:'Poppins Bold'
}



export const paperStyles = {
    // bottom: 0,
    // left: 0, 
    // right: 0,
    // paddingBottom:'5%',
    // backgroundColor:'#F5F5F5'

}

export const gridStyles =  {
    justifyContent:{
        md:"center"
    },   
    alignItems:"center",  
    padding:{lg:"300px 0px 300px 0px"}
}

export const gridItemStyles = {
    justifyContent:{
        sm:"right",
        md:"center"
    },
    alignItems:{
        sm:"right",
        md:"center"
    }
}
