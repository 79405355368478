import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import {cardContentStyles, cardMediaStyles, cardStyles, contentLabelStyles} from './styles'


const ContentCard = (props) =>{
    
  return (
    <Card sx={cardStyles}>
      <CardActionArea>
        <CardMedia
          sx = {cardMediaStyles}
          component="img"
          image={props.data.img}
          // alt={props.data.desc}
        />
        <CardContent sx={cardContentStyles}>
          <Typography sx={contentLabelStyles} gutterBottom variant="subtitle1" component="div">
            {(props.data.b?'Before':'After')}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default ContentCard;
