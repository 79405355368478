
export const imageListStyles = {
    padding:0
}

export const boxStyles = {
    marginTop: "5%",
    overflowY: 'scroll' 
}

export const stackStyles ={
    width:'100%',
    justifyContent:'center',
    marginTop:{
        xs:'15px',
        sm:'15px',
        md:'0px'
    },
    fontSize:{
        xs:'1.2rem',
        sm:'1.4rem',
        md:'1.6'
    },
    marginBottom:{
        xs:'15px',
        sm:'30px',
        md:'30px'
    }
}

export const linkButtonStyles ={
    maxWidth:{   
        xs:'40%',
        sm:'60%',
        md:'60%'},
    background:"#bc6630",
    '&:hover':{
            background: "#a15120 !important"
    },
}