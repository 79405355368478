
export const sectionHeaderStylesSmall = {
    fontSize:'50px',
    backgroundColor:'#bc6630',
    color:'white',
    fontFamily:'Poppins Medium',



    // color:'purple'
}

export const sectionHeaderStylesMedium = {
    fontSize:'60px',
    backgroundColor:'#bc6630',
    color:'white',
    fontFamily:'Poppins Medium',



    // color:'red'
}
//Maybe add Grey as a thirs theme color? Might make it pop more
export const sectionHeaderStylesLarge = {
    fontSize:'75px',
    backgroundColor:'#bc6630',
    color:'white',
    fontFamily:'Poppins Medium',


    // color:'green'
}

export const sectionHeaderStylesExtraLarge = {
    fontSize:'100px',
    backgroundColor:'#bc6630',
    color:'white',
    fontFamily:'Poppins Medium',

    // color:'blue'
}

export const sectionHeaderStyles = {
    
}