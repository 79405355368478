import { useState, useEffect } from 'react'
import { sectionHeaderStylesSmall,sectionHeaderStylesMedium,sectionHeaderStylesLarge,sectionHeaderStylesExtraLarge,sectionHeaderStyles } from './styles'
import { Paper, Tooltip } from '@mui/material'

const SectionHeader = (props) =>{
    const breakpoints = {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920
    }
    
    const getStyles = (width) => {
        if (width < breakpoints.sm) {
            return sectionHeaderStylesSmall
        } else if (width < breakpoints.md) {
            return sectionHeaderStylesMedium
        } else if (width < breakpoints.lg) {
            return sectionHeaderStylesLarge
        } else {
            return sectionHeaderStylesExtraLarge
        } 
    } 
    
    const [headerStyles, setHeaderStyles] = useState(getStyles(window.innerWidth))

    const updateDimensions = () => {
        setHeaderStyles(getStyles(window.innerWidth))
    }
    
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
      }, []);

    return(
        <Tooltip followCursor arrow title = {props.type}>
            <Paper variant = "elevation" elevation={2} sx={headerStyles}>
                {props.type}
            </Paper>
        </Tooltip>
    )
}

export default SectionHeader;