import React from 'react';
import Carousel from 'react-material-ui-carousel'
import CarouselItem from './CarouselItem'
import imageData from './contentImport'
const CarouselContainer = () => {
    return (
        <Carousel>
            {
                imageData().map( (item, i) => <CarouselItem key={i} item={item} /> )
            }
        </Carousel>
    )
}

export default CarouselContainer

