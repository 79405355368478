
export const buttonStyles = {
    fontSize : "Medium",
    fontWeight:350,
    backgroundColor:'#bc6630',
    '&:hover':{
        background: "#a15120"
    },
}

export const boxStyles = {
    paddingTop : {lg:'5%'}
}