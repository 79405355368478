import React from 'react';
import CarouselContainer from '../../Components/Carousel/Carousel'
import EngContent from '../../Components/AboutUs/ContentEng/Content'
import Paper from '@mui/material/Paper';
const Home = () =>(
    <Paper square = {true}>
        <CarouselContainer/>
        <EngContent/>
    </Paper>
)

export default Home;