import { lineHeight } from "@mui/system"

export const cardStyles = {
    maxWidth: {
        xs:'100%',
        md:'60%'
    },
    raised:{
        xs:false,
        md:true
    },
    justifyContent:'center',
    paddingBottom:{
        xs:'10%',
        sm:'0'
    }
}

export const nameStyles = {
    fontFamily:'Poppins'
    
}

export const bioStyles = {
    fontFamily:'Poppins',
    lineHeight:'1.6rem'
    
}

export const containerStyles ={
    display:'flex'
}