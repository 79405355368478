import PhotoCollage from '../../Components/PhotoCollage/PhotoCollage'


const Services = () =>{

    return (
        <PhotoCollage/>
    )
}

export default Services;