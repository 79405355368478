import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Button, Stack } from '@mui/material';
import imageData from './imageImport'
import { useState, useEffect } from 'react'
import SectionHeader from '../SectionHeader/SectionHeader'
import {imageListStyles, boxStyles, stackStyles, linkButtonStyles} from './styles'
import React, { createRef} from 'react'
export default function MasonryImageList() {

  const categories = ['Stone','Stucco','Concrete']

  const breakpoints = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920
}

const ref1 = createRef()
const ref2 = createRef()
const ref3 = createRef()
const refs = [ref1,ref2,ref3]

const getColumns = (width) => {
    if (width < breakpoints.sm) {
        return 2
    } else if (width < breakpoints.md) {
        return 3
    } else if (width < breakpoints.lg) {
        return 4
    } else if (width < breakpoints.xl) {
        return 4
    } else {
        return 4
    }
}

const [columns, setColumns] = useState(getColumns(window.innerWidth))
const updateDimensions = () => {
    setColumns(getColumns(window.innerWidth))
}

const handleClick =(index) =>{
  refs[index].current.scrollIntoView({behavior: 'smooth'});
}

useEffect(() => {
    updateDimensions()
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
    
  }, []);

  return (
  <Box sx={boxStyles}>
     <Stack direction="row" spacing={2} sx ={stackStyles}>
      {categories.map((category,index)=>{
        return(
        <Button sx = {linkButtonStyles} variant = 'contained' key = {category+index} onClick={()=>{
            handleClick(index)
        }}>
          {category}
        </Button>
        )
      })}
    </Stack>
    {categories.map((category,index)=>{
      return(
      <div key={index} ref = {refs[index]}>
        <SectionHeader type = {category}/>
        <ImageList sx ={imageListStyles} variant="masonry" cols={columns} gap={8}>
                {imageData()
                .filter(data=>data.type===category)
                .map((item,index) => (
                  <ImageListItem key={index}>
                    <img
                      src={`${item.img}`}
                      srcSet={`${item.img}`}
                      alt={item.title}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
      </div>)
    })}
  </Box>
  );
}

