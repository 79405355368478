import {headingStyles, pStyles, ulStyles, liStyles, cardStyles} from './styles'
import { Typography, Box, Grid, Card, TextField, CardContent,Button, ListItemIcon, ListItemText, List, ListItem, Collapse, ListItemButton } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HomeIcon from '@mui/icons-material/Home';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import React, {useState} from 'react';
const ContactContent = () =>{

    const [open, setOpen] = useState(true)
    
    const handleClick = () =>{
        setOpen(!open)
    }
    return (
            <Card variant="outlined" sx ={cardStyles}>
                <CardContent>
                <Typography gutterBottom variant="h5">
                    Contact Us
                </Typography> 
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <ContactPhoneIcon/>
                        </ListItemIcon>
                        <ListItemText>
                        Contact Number: (828)578-2755
                        </ListItemText>

                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            <HomeIcon/>
                        </ListItemIcon>
                        <ListItemText>
                            Address: 1112 Shannon Dr Newton, NC 28658
                        </ListItemText>

                    </ListItem>


                        <ListItemButton onClick={handleClick}>
                        <ListItemIcon>
                            <CalendarMonthIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Hours" />
                        {open ? <ExpandLess /> : <ExpandMore />}      
                        </ListItemButton>
                            <Collapse in = {open} timeout="auto" unmountOnExit>
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AccessTimeIcon/>
                                        </ListItemIcon>
                                        <ListItemText>
                                            Monday 9:00 AM - 5:00 PM
                                        </ListItemText>

                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AccessTimeIcon/>
                                        </ListItemIcon>
                                        <ListItemText>
                                            Tuesday 9:00 AM - 5:00 PM
                                        </ListItemText>

                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AccessTimeIcon/>
                                        </ListItemIcon>
                                        <ListItemText>
                                            Wednesday 9:00 AM - 5:00 PM
                                        </ListItemText>

                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AccessTimeIcon/>
                                        </ListItemIcon>
                                        <ListItemText>
                                            Thursday 9:00 AM - 5:00 PM
                                        </ListItemText>

                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AccessTimeIcon/>
                                        </ListItemIcon>
                                        <ListItemText>
                                        Friday 9:00 AM - 5:00 PM
                                        </ListItemText>
                                    </ListItem>
                                    
                                </List>
                            </Collapse>

                </List>
                </CardContent>
                
            </Card>
        // <div>
        //     <h3 style = {headingStyles}>Contact Us</h3>
        //     <ul style = {ulStyles}>
        //         <li style = {liStyles}>
        //         <strong>Contact Number:</strong> 828-578-2755
        //         </li>
        //         <li style = {liStyles} > 
        //             <strong>Address:</strong> 
        //         </li>
        //         <li style = {liStyles}>
        //         <strong>Available Hours:</strong>
        //             <ul style = {ulStyles}>
        //                 <li style = {liStyles}>
        //                     
        //                 </li>
        //             </ul>
        //         </li>
        //         <li style = {liStyles}>
        //         <strong>Let me know how we can improve your experience!</strong>
        //         </li>
        //     </ul>
        // </div>
    )
}

export default ContactContent;