import { Typography,Box} from "@mui/material"
import {titleStyles, subStyles, boxStyles} from './styles'


const ServiceAreas = () =>(
        <Box sx = {boxStyles}>
            <Typography sx = {titleStyles} variant = "h4" align = 'center'>
                Service Areas
            </Typography>
            <Typography sx = {subStyles} variant = 'subtitle1'>
                We offer residential and commerical services in Newton, Hickory, Statesville, Mooresville, Denver, and Charlotte.
            </Typography>
        </Box>
)

export default ServiceAreas;