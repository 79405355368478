import { Typography,Box} from "@mui/material"
import {titleStyles, subStyles, boxStyles} from './styles'


const ServicesInfo = () =>(
        <Box sx = {boxStyles}>
            <Typography sx = {titleStyles} variant = "h4" align = 'center'>
                Services
            </Typography>
            <Typography sx = {subStyles} variant = 'subtitle1'>
                Stucco Repair, Stucco Window Trim, Stucco Outside Oven, Hard Coat, EIFS, Flag Stone, Stone Fireplaces, Stone Columns, Stone Mailbox, Stamp Concrete, Concrete Driveway, Concrete Sidewalks.
            </Typography>
        </Box>
)

export default ServicesInfo;