import { Typography,Box} from "@mui/material"
import {titleStyles, subStyles, boxStyles} from './styles'


const AdditionalInfo = () =>(
        <Box sx = {boxStyles}>
            <Typography sx = {titleStyles} variant = "h4" align = 'center'>
                Additional Info
            </Typography>
            <Typography sx = {subStyles} variant = 'subtitle1'>
                We speak Spanish and English so rest assured we will be able to service you! We also have insurance!
            </Typography>

        </Box>
)

export default AdditionalInfo;