export const boxStyles = {
    padding:{
        xs:'5px 10px 5px 10px',
        // lg:'70px 0px 30px 0px'
    },
    maxWidth: {
        xs:'100%',
        md:'500px'
    },
    // backgroundColor:{
    //     xs : 'red',
    //     sm:'orange',
    //     md: 'yellow',
    //     lg: 'green',
    //     xl: 'transparent'
    // }
}

export const titleStyles = {
    fontFamily:'Poppins'
}

export const subStyles = {
    lineHeight:"2.5rem",
    fontFamily:'Poppins Light',
    // fontSize:{
    //     md:'1.1rem'
    // }
    fontSize:'.9rem'
}


