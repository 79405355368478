import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import image from '../../Images/enrique.jpg'
import {cardStyles, nameStyles, bioStyles, containerStyles} from './styles'
const  MediaCard = () => {
  return (
        <Card  sx={cardStyles}>
          <CardActionArea>
            <CardMedia
              component="img"
            //   height="140"
              image={image}
              alt="Enrique Mejia"
            />
            <CardContent>
              <Typography sx={nameStyles} gutterBottom variant="h5" component="div">
                Enrique Mejia
              </Typography>
              <Typography sx ={bioStyles} variant="body2" color="text.secondary">
              Hi, my name is Enrique Mejia. I am a small business owner in Newton. I have 15 years of experience and can assist you with the following services listed. If you have any questions feel free to real out via our contact form or reach out to me directly.
              </Typography>
            </CardContent>
          </CardActionArea>
          {/* <CardActions>
            <Button size="small" color="primary">
              Share
            </Button>
          </CardActions> */}
        </Card>
  );
}

export default MediaCard;