export const gridStyles = {
    justifyContent:"center",
    alignItems:"center"}

export const gridItemStyles = {
    justifyContent:"center",
    alignItems:"center",
}

export const gridArrowStyles = {
    justifyContent:"right",
    alignItems:"right",
    transform: 'translateX(25%)',
    display:{
        xs:"none",
        sm:"block",
        md:"block",
        lg:"block",
        xl:"block"
    }
}