import { Box } from '@mui/material'
import CompareImages from '../CompareImages/CompareImages'
import { boxStyles } from './styles'
const CarouselItem = (props) =>{
    return (
        <Box sx = {boxStyles}>
            <CompareImages compareData = {props.item}/>
        </Box>
    )
}

export default CarouselItem