import React from 'react';
import ContactForm from '../../Components/ContactForm/ContactForm';
import ContactContent from '../../Components/ContactContent/ContactContent'
import {contactStyles} from './styles'
import {Grid} from '@mui/material';
const Contact = () =>{
    return(
    <Grid container spacing = {0} sx = {contactStyles}>
        <Grid item xs= {12} md = {6}>
            <ContactContent/>
        </Grid>
        <Grid item xs= {12} md = {6}>
            <ContactForm/>
        </Grid>
    </Grid>
    )
}

export default Contact;