import Before1 from '../../Images/Before1.jpg'
import Before2 from '../../Images/Before2.jpg'
import Before3 from '../../Images/Before3.jpg'
import After1 from '../../Images/After1.jpg'
import After2 from '../../Images/After2.jpg'
import After3 from '../../Images/After3_2.jpg'

const beforeImages = [Before1,Before2,Before3]

const afterImages = [After1,After2,After3]


const descriptions = ["This is a sample","This is a sample","This is a sample"]
const createImageConmpareItem = (imageBefore,imageAfter, desc) =>(
{   
    before:{
        img:imageBefore,
        b:true
    },
    after:{
        img: imageAfter,
        b:false
    },
    description:desc
}
)
const imageData = () =>{
    const imageObjects = []
    for(let i = 0; i<beforeImages.length;i++){
        imageObjects.push(createImageConmpareItem(beforeImages[i],afterImages[i],descriptions[i]))
    }

    return imageObjects
}

export default imageData