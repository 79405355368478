import { useState, useRef } from "react";
import { CustomForm } from "./CustomForm/CustomForm";
import { formStyles, customFormContainer, buttonStyles, cardStyles, rowSpacing } from "./styles";
import { Typography, Box, Grid, Card, TextField, CardContent,Button } from '@mui/material';
import defaultInstance from '../../Utils/defaultInstance';
import emailjs from '@emailjs/browser';

const inputFieldValues = [
  {
    name: "firstName",
    label: "First Name",
    id: "my-firstName"
  },
  {
    name: "lastName",
    label: "Last Name",
    id: "my-lastName"
  },
  {
    name: "email",
    label: "Email",
    id: "my-email"
  },
  {
    name: "message",
    label: "Message",
    id: "my-message",
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    id: "my-phoneNumber",
  }
];

const ContactForm = () => {

  const form = useRef();


  // const client = new SMTPClient({
  //   user: 'emejia@stuccostoneconstruction',
	//   password: 'yqgbcerizjfnrnul',
	//   ost: 'smtp.emejia@stuccostoneconstruction.com',
	//   ssl: true,
  // })



    const initialFormValues = {
        firstName: "",
        lastName: "",
        email: "",
        message: "",
        phoneNumber:"",
        formSubmitted: false,
        success: false
      };


    const [values, setValues] = useState(initialFormValues);
    const [errors, setErrors] = useState({});

    // const resetForm = () =>{

    // }

    // const sendAlert = () =>{

    // }

    
    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("firstName" in fieldValues)
            temp.fullName = fieldValues.fullName ? "" : "This field is required.";

        if ("lastName" in fieldValues)
        temp.fullName = fieldValues.fullName ? "" : "This field is required.";

        if ("email" in fieldValues) {
            temp.email = fieldValues.email ? "" : "This field is required.";
            if (fieldValues.email)
            temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
                ? ""
                : "Email is not valid.";
        }
        if ("message" in fieldValues)
            temp.message =
            fieldValues.message.length !== 0 ? "" : "This field is required.";

        setErrors({
            ...temp
        });
    };
    
    
    
    const handleInputValue = (e) => {
        const { name, value } = e.target;
        switch(name) {
          case 'firstName':
            setValues({
              ...values,
              'firstName':value
            });
            break;
          case 'lastName':
            setValues({
              ...values,
              'lastName':value
            });
            break;
          case 'email':
            setValues({
              ...values,
              'email':value
            });
            break;
          case 'message':
            setValues({
              ...values,
              'message':value
            });
            break;
          case 'phoneNumber':
            setValues({
              ...values,
              'phoneNumber':value
            });
            break;
        }
        
        validate({ name: value });
    };
    
    
    
    
        //Need To look at this
    const formIsValid = (fieldValues = values) => {
        const isValid =
            fieldValues.firstName &&
            fieldValues.lastName &&
            fieldValues.email &&
            fieldValues.message &&
            Object.values(errors).every((x) => x === "");
    
        return isValid;
    };
    
    
    
        
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const isValid = Object.values(errors).every((x) => x === "") && formIsValid();
        const data = {
          name: values.firstName+' '+values.lastName,
          email: values.email,
          message: values.message
          }
        if (isValid) {
          emailjs.sendForm(process.env.REACT_APP_SERID, process.env.REACT_APP_TEMPID, form.current, process.env.REACT_APP_K)
        .then((result) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
      });
        } else{
          console.log('form was not valid')
        }
    };

  
  return (

        <Card variant="outlined" sx = {cardStyles}>
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
              Fill out the form and our team will get back to you within 24 hours.
          </Typography> 
            <form ref={form}>
              <Grid container rowSpacing={rowSpacing}>
                <Grid xs={12} sm={6} item>
                  <TextField name ="firstName" onChange={handleInputValue} placeholder="Enter first name" label="First Name" variant="outlined" fullWidth required />
                </Grid>
                <Grid xs={12} sm={6} item>
                  <TextField name ="lastName" onChange={handleInputValue} placeholder="Enter last name" label="Last Name" variant="outlined" fullWidth required />
                </Grid>
                <Grid item xs={12}>
                  <TextField name ="email" type="email" onChange={handleInputValue} placeholder="Enter email" label="Email" variant="outlined" fullWidth required />
                </Grid>
                <Grid item xs={12}>
                  <TextField name ="phoneNumber" onChange={handleInputValue} placeholder="Enter phone number" label="Phone" variant="outlined" fullWidth/>
                </Grid>
                <Grid item xs={12}>
                  <TextField  name ="message" onChange={handleInputValue} label="Message" multiline rows={4} placeholder="Type your message here" variant="outlined" fullWidth required />
                </Grid>
                <Grid item xs={12}>
                  <Button onClick = {handleFormSubmit} onChange={handleInputValue} type="submit" variant="contained" color="primary" fullWidth>Submit</Button>
                </Grid>

              </Grid>
            </form>
          </CardContent>
        </Card>
  );
};

export default ContactForm;
