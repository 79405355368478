import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import BuildIcon from '@mui/icons-material/Build';
import FacebookIcon from '@mui/icons-material/Facebook';
import Paper from '@mui/material/Paper';
import React,{useState} from 'react';
import {paperStyles,bottomNavStyles} from './styles'
import {Link} from 'react-router-dom';
import { Tooltip } from '@mui/material';


const Footer = () =>{

    return(
    <Paper sx={paperStyles} elevation={2}>
      <BottomNavigation
      sx = {bottomNavStyles}
        showLabels = {true}>
          <BottomNavigationAction component = {Link} to = '/contact' label="Contact Us" icon={<ContactMailIcon/>} />
          <BottomNavigationAction component = {Link} to = '/services' label="Services" icon={<BuildIcon />} />
          <BottomNavigationAction component = {Link} to = '/' label="Facebook" icon={<FacebookIcon />} />
      </BottomNavigation>
   </Paper>
    )
}

export default Footer;