import stone1 from '../../Images/stone1.jpg'
import stone2 from '../../Images/stone2.jpg'
import stone3 from '../../Images/stone3.jpg'
import stone4 from '../../Images/stone4.jpg'
import stone5 from '../../Images/stone5.jpg'
import stone6 from '../../Images/stone6.jpg'
import stone7 from '../../Images/stone7.jpg'
import stone8 from '../../Images/stone8.jpg'


import concrete1 from '../../Images/concrete1.jpg'
import concrete2 from '../../Images/concrete2.jpg'
import concrete3 from '../../Images/concrete3.jpg'
import concrete4 from '../../Images/concrete4.jpg'
import concrete5 from '../../Images/concrete5.jpg'
import concrete6 from '../../Images/concrete6.jpg'
import concrete7 from '../../Images/concrete7.jpg'

import stucco1 from '../../Images/stucco1.jpg'
import stucco2 from '../../Images/stucco2.jpg'
import stucco3 from '../../Images/stucco3.jpg'
import stucco4 from '../../Images/stucco4.jpg'
import stucco5 from '../../Images/stucco5.jpg'
import stucco6 from '../../Images/stucco6.jpg'
import stucco7 from '../../Images/stucco7.jpg'
import stucco8 from '../../Images/stucco8.jpg'

const stoneImages = [
    {
        img:stone1,
        type:'Stone',
        description:'empty'
    },
    {
        img:stone2,
        type:'Stone',
        description:'empty'
    },
    {
        img:stone3,
        type:'Stone',
        description:'empty'
    },
    {
        img:stone4,
        type:'Stone',
        description:'empty'
    },
    {
        img:stone5,
        type:'Stone',
        description:'empty'
    },
    {
        img:stone6,
        type:'Stone',
        description:'empty'
    },
    {
        img:stone7,
        type:'Stone',
        description:'empty'
    },
    {
        img:stone8,
        type:'Stone',
        description:'empty'
    },
]

const concreteImages = [
    {
        img:concrete1,
        type:'Concrete',
        description:'empty'
    },
    {
        img:concrete2,
        type:'Concrete',
        description:'empty'
    },
    {
        img:concrete3,
        type:'Concrete',
        description:'empty'
    },
    {
        img:concrete4,
        type:'Concrete',
        description:'empty'
    },
    {
        img:concrete5,
        type:'Concrete',
        description:'empty'
    },
    {
        img:concrete6,
        type:'Concrete',
        description:'empty'
    },
    {
        img:concrete7,
        type:'Concrete',
        description:'empty'
    },
]
const stuccoImages = [
    {
        img:stucco1,
        type:'Stucco',
        description:'empty'
    },
    {
        img:stucco2,
        type:'Stucco',
        description:'empty'
    },
    {
        img:stucco3,
        type:'Stucco',
        description:'empty'
    },
    {
        img:stucco4,
        type:'Stucco',
        description:'empty'
    },
    {
        img:stucco5,
        type:'Stucco',
        description:'empty'
    },
    {
        img:stucco6,
        type:'Stucco',
        description:'empty'
    },
    {
        img:stucco7,
        type:'Stucco',
        description:'empty'
    },
    {
        img:stucco8,
        type:'Stucco',
        description:'empty'
    },
]


const imageData = ()=>{
    const images = [
        ...stoneImages,...concreteImages,...stuccoImages
    ];
    return images
}

export default imageData