export const headingStyles = {
fontSize:'32px'
}

export const pStyles = {

}

export const ulStyles = {
    textDecoration:'none',
    listStyle:'none'
}

export const liStyles = {
    fontSize:'26',
    margin: '10px 0px'
}

export const cardStyles ={
    height: '100%',
    border:'none',
    borderRadius:'0'
}